import { IResourceComponentsProps, useCustom, useLogout, useTranslate } from "@refinedev/core"
import "./styles.css";
import { ConfigProvider, QRCode, Spin } from "antd";
import { useState } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { API_URL, DOMAIN_URL } from "../../constants";
import { MenuCustom } from "components/menu_footer";

export const CardID: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const TOKEN: any = localStorage.getItem("AUTH");
    const USERID: any = localStorage.getItem("USER");
    const [tk, settk] = useState<boolean>(true);
    const [isLoading, setLoading] = useState<boolean>(true);
    const { mutate: mutateLogout } = useLogout();

    const [userProfile, setuserProfile] = useState<any>();
    const [pixURL, setpixURL] = useState<any>();

    const { data: dataUser, refetch: refetchUser} = useCustom<any>({
        url: `${API_URL}/api/user-profile/find_by_user`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
        },
        queryOptions:{
            async onSuccess(data) {
                let dataLoad: any = data?.data;

                setuserProfile((pre: any) => dataLoad?.user_profile);
                if(dataLoad?.user_profile?.line_pictureUrl){
                    setpixURL(dataLoad?.user_profile?.line_pictureUrl);
                }else{
                    setpixURL(undefined);
                }

                settk(!tk);
                await getTheme(dataLoad?.user_profile?.current_theme || "styles-main");
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            },
            onError(err: any){
                localStorage?.clear();
                mutateLogout();
            }
        },
    });

    async function getTheme(value: any){
        let theme: any = value;
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `${DOMAIN_URL}/theme/${theme}.css`;
        document.head.appendChild(link);
    }

    return(
        <ConfigProvider
            theme={{
                token: {
                    colorBgMask: "rgba(27, 26, 24, 1)",
                    colorPrimary: "#f07d51",
                },
            }}
        >
            <Spin 
                spinning={isLoading} 
                // indicator={
                //     <div className="custom-spin-dot">
                //         <LoadingOutlined style={{marginRight: "15px"}}/>
                //         <span>{t("โหลดข้อมูล")}</span>
                //     </div>
                // }
                indicator={<></>}
                fullscreen={isLoading}
            >
                <div className="layout-profile">
                    <div className="panel-container-profile">
                    <div className="panel-header">
                        <div className="font-header">
                            <span className="main">{t("i24")}</span>
                            <span className="sub-main">{t("HOURS")}</span>
                        </div>
                    </div>
                    <div className="panel-body panel-full-h" style={{padding: "0px 20px"}}>
                        <div className="flip-card" style={{width: "70%"}}>
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <div className="bar-card"><div className="xxe">{userProfile?.first_name}</div></div>
                                    <div className="detail-card" style={{backgroundImage: `url(${pixURL})`}} />
                                </div>
                                <div className="flip-card-back">
                                    <div className="detail-card" style={{backgroundImage: `url(${pixURL})`}}>
                                    <QRCode
                                        value={`${DOMAIN_URL}/detail-id-card/${USERID}`}
                                        color={"#000"}
                                        bgColor={"#fff"}
                                    />
                                    </div>
                                    <div className="bar-card"><div className="xxe">{userProfile?.first_name}</div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-menu"><MenuCustom/></div>
                    </div>
                </div>
            </Spin>
        </ConfigProvider>
    )
}