import { useNavigation } from "@refinedev/core";
import "./styles.css";
import { ClockCircleOutlined, HistoryOutlined, ShopOutlined, UserOutlined, AuditOutlined  } from '@ant-design/icons';
import { DOMAIN_URL } from "../../constants";

export const MenuCustom = () => {

  const { push } = useNavigation();
  
  return (
    <div className="menu-custom-grid">
      <div className="item-menu"><div onClick={() => push("/leave-work")}><AuditOutlined style={{fontSize: "20px", cursor: "pointer"}}/></div></div>
      <div className="item-menu"><div onClick={() => push("/market")}><ShopOutlined style={{fontSize: "20px", cursor: "pointer"}}/></div></div>
      <div className="item-menu item-center">
        <div className="panel-back">
          <div className="panel-btn" onClick={()=> window.location.href = `${DOMAIN_URL}/check-in`}>
            <ClockCircleOutlined style={{fontSize: "40px",color: '#ffff'}}/>
          </div>
        </div>
      </div>
      <div className="item-menu"><div onClick={() => push("/history")}><HistoryOutlined style={{fontSize: "20px", cursor: "pointer"}}/></div></div>
      <div className="item-menu"><div onClick={() => push("/profile")}><UserOutlined style={{fontSize: "20px", cursor: "pointer"}}/></div></div>
    </div>
  );
};