import { RefineThemedLayoutV2HeaderProps } from "@refinedev/antd"
import { useCustom, useGetIdentity, useLogout, useNavigation, useTranslate } from "@refinedev/core";
import { Avatar, MenuProps, theme, Layout as AntdLayout, Space, Dropdown, Typography, Select, Popover, Divider, Tag} from "antd";
import { useTranslation } from "react-i18next";
import React, { useContext, useState } from "react";
import "./styles.css";

import { DownOutlined, LogoutOutlined, EditOutlined, UserOutlined, HomeOutlined, CheckOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { DOMAIN_URL, TOKEN_KEY } from "../../../constants";
import type { SelectProps } from 'antd';

let nf = new Intl.NumberFormat('en-US');
export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({ sticky,}) => {
    
    const t = useTranslate();
    const { Text } = Typography;
    const { useToken } = theme;
    const { token } = useToken();
    const { data: fileUser } = useGetIdentity<any>();
    const { mutate: mutateLogout } = useLogout();
    const { push } = useNavigation();

    const COMP: any = localStorage.getItem("COMP");
    const ROLE: any = localStorage.getItem("ROLE");
    const LV: any = localStorage.getItem("LV");
    let page = window?.location?.href.split("/");
    let pageTOKEN :any = page[3] ? page[3] : undefined;
    
    const items: MenuProps['items'] = [
        {
            label: 'Edit Profile',
            key: 'edit-profile',
            icon: <EditOutlined />,
        },
        {
            label: 'Logout',
            key: 'logout',
            danger: true,
            icon: <LogoutOutlined />,
        },
    ];

    const onClick: MenuProps['onClick'] = ({ key }) => {
        if(key == "edit-profile"){
            push("/account")
        }else if(key == "logout"){
            mutateLogout();
        }
    };

    const headerStyles: React.CSSProperties = {
        // backgroundColor: token.colorBgElevated,
        backgroundColor: "#fff",
        display: "flex",
        // justifyContent: "flex-end",
        // alignItems: "center",
        padding: "0px 24px",
        height: "64px",
    };

    if (sticky) {
        headerStyles.position = "sticky";
        headerStyles.top = 0;
        headerStyles.zIndex = 1;
    }

    function changeComp(id: any){
        localStorage?.setItem("COMP", id);
        localStorage?.removeItem("ROLE");
        localStorage?.removeItem("LV");
        window?.location?.reload();
    }

    function changeRole(id: any){
        localStorage?.setItem("ROLE", id);
        localStorage?.removeItem("ROLE");
        localStorage?.removeItem("LV");
        window?.location?.reload();
    }

    const content = () => (
        <div style={{minWidth: "200px", maxWidth: "400px", padding: "0.5rem"}}>
            {/* <div className="role_select_panel">
                <div style={{marginBottom: "1rem"}}><Tag color="#AFDC47">{t("Role List")}</Tag></div>
                {fileUser?.role?.length > 0 && fileUser?.role?.map((item: any, key: any) => {
                    return (
                        <div key={key} style={{cursor: "pointer", marginBottom: "1rem", backgroundColor: ROLE == item?._id? "#AFCAED": "transparent", padding: ROLE == item?._id? "5px 10px" :"0 0", borderRadius: "5px"}} onClick={() => changeRole(item?._id)}>
                            <span style={{color: ROLE == item?._id? "#fff": "#000", marginRight: "1rem"}}>{t(item?.name_en)}</span>
                            <span style={{float: "right"}}>{ROLE == item?._id && <CheckOutlined style={{color: "#fff"}}/>}</span>
                        </div>
                    )
                })}
            </div>

            <Divider style={{margin: 12}}/> */}

            <div className="menu_icon_header">
                {/* <div style={{cursor: "pointer", marginBottom: "1rem"}} onClick={() => push("/company")}>
                    <span style={{marginRight: "1rem"}}><HomeOutlined /></span>
                    <span>{t("Change Company")}</span>
                </div> */}
                <div style={{cursor: "pointer", marginBottom: "1rem"}} onClick={() => push("/account")}>
                    <span style={{marginRight: "1rem"}}><EditOutlined /></span>
                    <span>{t("Edit Profile")}</span>
                </div>
                <div style={{cursor: "pointer", color: "#ff4d4f"}} onClick={() => mutateLogout()}>
                    <span style={{marginRight: "1rem"}}><LogoutOutlined /></span>
                    <span>{t("Logout")}</span>
                </div>
            </div>
        </div>
    )

    return(
        <AntdLayout.Header style={headerStyles}>
            <div style={{width: "50%", display: "flex", paddingLeft: "25px"}}><img style={{width: "100px"}} src={`${DOMAIN_URL}/images/svg/logo.svg`} alt="icn"/></div>
            <div style={{width: "50%", display: "flex", justifyContent: "end", alignItems: "center"}}>
                <Avatar style={{marginRight: "10px"}} className="avatar_header" icon={<UserOutlined />} size={32} src={fileUser?.user_profile?.avatar}/>
                <Popover content={content} trigger="hover" placement="bottomRight">
                    <div className="avatar_header_menu">
                        <Text 
                            style={{color: "#9CA3AF", marginRight: "1rem", fontWeight: "600", textTransform: "uppercase"}}
                        >
                            {
                                fileUser?.user_profile?.first_name == "" && fileUser?.user_profile?.last_name =="" ?
                                `User ${fileUser?.user_profile?.id}`
                                :
                                fileUser?.user_profile?.first_name + " " + fileUser?.user_profile?.last_name
                            }
                        </Text>
                        <DownOutlined style={{color: "#9CA3AF"}}/>
                    </div>
                </Popover>
            </div>
        </AntdLayout.Header>
    )
}