import { Authenticated, GitHubBanner, Refine, WelcomePage, useGetIdentity } from "@refinedev/core";
import { DevtoolsPanel, DevtoolsProvider } from "@refinedev/devtools";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import routerBindings, {
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import routerProvider, { CatchAllNavigate } from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import "./App.css";
import { LoginPage } from "pages/unauth/login";
import { authProvider } from "authProvider";
import { ThemedLayoutV2, ErrorComponent } from "@refinedev/antd";
import { Header, Sider } from "./components/layout";
import { AppIcon } from "components/app-icon";
import { Register } from "pages/unauth/register";
import { ForgotpwdPage } from "pages/unauth/forgot-pwd";
import { Dashboard } from "pages/dashboard";
import { CheckIN } from "pages/check-in";
import { Profile } from "pages/profile";
import { useEffect } from "react";
import { HistoryPage } from "pages/history-check-in";
import { MarketPage } from "pages/market";
import { Devvspo } from "pages/games";
import { CardID } from "pages/profile/card-id";
import { DetailByID } from "pages/profile/detail-by-id";
import { LeaveWork } from "pages/leave-work";
import { LeaveWorkPage } from "pages/leave-work/leave-page";
import { ScoreboardPage } from "pages/score-board";

function App() {
  const titleHandler: any = ({ resource, action, params }: { resource: string; action: string; params: { id: string } }): string => {
    let title = "i24HOURS"; // Default title
  
    return title;
  };

  // const sty = async () => {
  //   let styleApi = "styles1"
  //   const link = document.createElement('link');
  //   link.rel = 'stylesheet';
  //   link.href = 'http://192.168.100.131/my-styles/styles-main.css';
  //   document.head.appendChild(link);
  // }

  // useEffect(() => {
  //   sty()
  // }, [])

  return (
    <BrowserRouter>
      <GitHubBanner />
      <RefineKbarProvider>
        {/* <DevtoolsProvider> */}
          <Refine
            authProvider={authProvider}
            routerProvider={routerBindings}
            dataProvider={dataProvider("https://api.fake-rest.refine.dev")}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: false,
              useNewQueryKeys: true,
              projectId: "wBB9wM-vTF22S-nxpHVn",
            }}
            resources={[]}
          >
            <DocumentTitleHandler handler={titleHandler}/>
            <Routes>
              <Route
                  element={
                      <Authenticated
                          key="authenticated-routes"
                          fallback={
                              <CatchAllNavigate to="/login" />
                          }
                      >
                          <ThemedLayoutV2
                            Header={() => <Header sticky />}
                            Sider={Sider}
                            Title={AppIcon}
                          >
                              <Outlet />
                          </ThemedLayoutV2>
                      </Authenticated>
                  }
              >
                <Route path="/dashboard" element={<Dashboard/>} />
              </Route>
              <Route 
                element={
                  <Authenticated
                      key="authenticated-routes"
                      fallback={<CatchAllNavigate to="/login" />}
                  >
                      <Outlet />
                  </Authenticated>
                }
              >
                <Route path="/check-in" element={<CheckIN />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/profile/card-id" element={<CardID />} />
                <Route path="/history" element={<HistoryPage />} />
                <Route path="/market" element={<MarketPage />} />
                <Route path="/leave-work" element={<LeaveWorkPage />} />
                <Route path="/score-board" element={<ScoreboardPage />} />
                {/* <Route path="/leave-work/leave-mode/:id" element={<LeaveWorkPage />} /> */}
                {/* <Route path="/leave-work/type-two" element={<LeaveWorkTypeTWO />} />
                <Route path="/leave-work/type-three" element={<LeaveWorkTypeTHREE />} /> */}
                {/* <Route path="/games/dev-vs-po" element={<Devvspo />} /> */}
              </Route>
              <Route
                  element={
                      <Authenticated
                          key="auth-pages"
                          fallback={<Outlet />}
                          
                      >
                          <NavigateToResource resource="posts" />
                      </Authenticated>
                  }
              >
                  <Route index element={<LoginPage />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/forgot-pwd" element={<ForgotpwdPage />} />
                  <Route path="/detail-id-card/:id" element={<DetailByID />} />
              </Route>
              <Route
                  element={
                      <Authenticated key="catch-all">
                          <ThemedLayoutV2>
                              <Outlet />
                          </ThemedLayoutV2>
                      </Authenticated>
                  }
              >
                  <Route path="*" element={<ErrorComponent />} />
              </Route>
            </Routes>
            <RefineKbar />
            <UnsavedChangesNotifier />
          </Refine>
          {/* <DevtoolsPanel /> */}
        {/* </DevtoolsProvider> */}
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;