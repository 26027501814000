// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.btn-reset-pwd {
    height: 65px;
    background-color: #E53D2A;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.btn-reset-pwd:hover {
    background-color: #F14E3B !important;
}

@media only screen and (max-width: 1500px) {
    .btn-reset-pwd {
        height: 55px;
        font-size: 18px;
    }
}


@keyframes fx_btn_reset_pwd {
    0% {box-shadow: rgb(255, 255, 255) 0px 0px 0px 3px, rgb(21,107,215,1) 0px 0px 10px 5px;}
    50% {box-shadow: unset;}
    100% {box-shadow: rgb(255, 255, 255) 0px 0px 0px 3px, rgb(21,107,215,1) 0px 0px 10px 5px;}
}`, "",{"version":3,"sources":["webpack://./src/pages/unauth/forgot-pwd/styles.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,yBAAyB;IACzB,eAAe;IACf,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI;QACI,YAAY;QACZ,eAAe;IACnB;AACJ;;;AAGA;IACI,IAAI,kFAAkF,CAAC;IACvF,KAAK,iBAAiB,CAAC;IACvB,MAAM,kFAAkF,CAAC;AAC7F","sourcesContent":["\n.btn-reset-pwd {\n    height: 65px;\n    background-color: #E53D2A;\n    font-size: 20px;\n    text-transform: uppercase;\n    letter-spacing: 1px;\n}\n\n.btn-reset-pwd:hover {\n    background-color: #F14E3B !important;\n}\n\n@media only screen and (max-width: 1500px) {\n    .btn-reset-pwd {\n        height: 55px;\n        font-size: 18px;\n    }\n}\n\n\n@keyframes fx_btn_reset_pwd {\n    0% {box-shadow: rgb(255, 255, 255) 0px 0px 0px 3px, rgb(21,107,215,1) 0px 0px 10px 5px;}\n    50% {box-shadow: unset;}\n    100% {box-shadow: rgb(255, 255, 255) 0px 0px 0px 3px, rgb(21,107,215,1) 0px 0px 10px 5px;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
