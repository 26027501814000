import { useThemedLayoutContext } from "@refinedev/antd";
import { DOMAIN_URL } from "../../constants";
import React from "react";
import { FileOutlined } from '@ant-design/icons';

export const AppIcon: React.FC = () => {
  const { siderCollapsed } = useThemedLayoutContext();
  
  return (
  <>
    {siderCollapsed == false && <div style={{fontSize: "22px", fontStyle: "italic", fontWeight: "bold", color: "#FFF", textAlign: "center"}}>ZEED</div>
      // <img style={{width: "100%"}} src={`${DOMAIN_URL}/images/svg/landding_svg/logo-light.svg`} alt="NUEMEK"/>
    }
        {siderCollapsed == true && <div style={{fontSize: "22px", fontStyle: "italic", fontWeight: "bold", color: "#FFF", textAlign: "center"}}>ZEED</div>
      // <img style={{width: "60%"}} src={`${DOMAIN_URL}/images/svg/logo-light.svg`} alt="NUEMEK"/>
    }
  </>
  );
};
