import { IResourceComponentsProps, useCustom, useLogout, useNavigation, useTranslate } from "@refinedev/core"
import "./styles.css";
// import "./script.js";
import { Col, Row, Spin, ConfigProvider, Avatar, Badge, notification } from "antd";
import { API_URL, DOMAIN_URL } from "../../constants";
import { useEffect, useState } from "react";
import { axiosInstance } from "@refinedev/strapi-v4";
import dayjs from "dayjs";
import { HistoryOutlined, LoadingOutlined, NotificationOutlined  } from '@ant-design/icons';
import { MenuCustom } from "components/menu_footer";

let nowdate: any = new Date();
export const CheckIN: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { push } = useNavigation();
    const TOKEN: any = localStorage.getItem("AUTH");
    const [crtime, setcrtime] = useState<any>();
    const [timestamp, settimestamp] = useState<any>();
    const [statusstamp, setstatusstamp] = useState<any>();
    const [tk, settk] = useState<boolean>(true);
    const [isLoading, setLoading] = useState<boolean>(true);
    const { mutate: mutateLogout } = useLogout();

    const [userProfile, setuserProfile] = useState<any>();
    const [themeCurrent, setthemeCurrent] = useState();

    const { data: dataUser, refetch: refetchUser} = useCustom<any>({
        url: `${API_URL}/api/user-profile/find_by_user`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
        },
        queryOptions:{
            async onSuccess(data) {
                let dataLoad: any = data?.data;

                setuserProfile((pre: any) => dataLoad?.user_profile);

                settk(!tk);
                await getTheme(dataLoad?.user_profile?.current_theme || "styles-main");
                setTimeout(() => {
                    getTime();
                }, 300);

            },
            onError(err: any){
                localStorage?.clear();
                mutateLogout();
            }
        },
    });


    function getTime(){
        axiosInstance.get(`${API_URL}/api/attendances/curtime2`, { headers: { Authorization: `Bearer ${TOKEN}` } })
        .then((res) => {
            let respon: any = res?.data;
            setcrtime((pre: any) => respon);
            settk(!tk);
            setTimeout(() => {
                getstamp();
            }, 300);
        })
        .catch(error => {
            console.error('error ',error);
        });
    }

    async function getTheme(value: any){
        let theme: any = value;
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `${DOMAIN_URL}/theme/${theme}.css`;
        // link.href = `${DOMAIN_URL}/theme/styles-space-x.css`;
        document.head.appendChild(link);
    }

    function getstamp(){
        axiosInstance.get(`${API_URL}/api/attendances/last_time`,
        { headers: { Authorization: `Bearer ${TOKEN}` } }
        )
        .then((response) => {
            let res = response?.data;
            settimestamp((pre: any) => res);
            settk(!tk);

            setTimeout(() => {
                setLoading(false);
            }, 300);
        })
        .catch(error => {

        });
    }

    function checked(){
        axiosInstance.get(`${API_URL}/api/clock/ez_clock`,
        { headers: { Authorization: `Bearer ${TOKEN}` } }
        )
        .then((response) => {
            let res = response?.data;
            setstatusstamp((pre: any) => res);
            setLoading(true);
            settk(!tk);
            setTimeout(() => {
                getTime();
            }, 300);
        })
        .catch(error => {

        });
    }

    // const aa = () => {
    //     let hrElement;
    //     let width = 450;
    //     let counter = 20;
    //     let container = document.getElementById(`fx-panel`);
    //     if(container){

    //         for (let i = 0; i < counter; i++) {
    //           hrElement = document.createElement("HR");
    //           if (i == counter - 1) {
    //             hrElement.className = "thunder";
    //           } else {
    //             hrElement.style.left = Math.floor(Math.random() * width) + "px";
    //             hrElement.style.animationDuration = 1 + Math.random() * 1 + "s";
    //             hrElement.style.animationDelay = Math.random() * 5 + "s";
    //           }
            

    //           hrElement.innerHTML = "";
    //           container.appendChild(hrElement);

    //         //   container.(hrElement);
    //         //   document.body.appendChild(hrElement);
    //         }
    //     }
    // }

    // useEffect(() => {
    //  aa()
    // }, [isLoading])
    
    
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgMask: "rgba(27, 26, 24, 1)",
                    colorPrimary: "#f07d51",
                },
            }}
        >
            <Spin 
                spinning={isLoading} 
                // indicator={
                //     <div className="custom-spin-dot">
                //         <LoadingOutlined style={{marginRight: "15px"}}/>
                //         <span>{t("โหลดข้อมูล")}</span>
                //     </div>
                // }
                indicator={<></>}
                fullscreen={isLoading}
            >
                <div className="layout-check-in">
                    <div id="body-containers" className="body-container-check-in">
                        <div className="panel-header">
                            <div className="font-header">
                                <span className="main">{t("i24")}</span>
                                <span className="sub-main">{t("HOURS")}</span>
                            </div>
                        </div>
                        <div className="panel-body panel-full-h" style={{padding: "0 30px"}}>
                            {/* <div>
                                <div style={{textAlign: "center", color: "#fff", opacity: "0.7", marginBottom: "15px"}}>{dayjs(nowdate).format("DD/MM/YYYY")}</div>
                                <div className="curren-time" style={{textAlign: "center"}}>{crtime}</div>
                                <div className="layout-circle-check-in">
                                    <div className="circle-check-in-last">
                                        <div className="circle-check-in-middle">
                                            <div className={`${timestamp?.time_in == '-:-' ? 'circle-check-in-first' : 'circle-check-out-first' }`} style={{cursor: "pointer"}} onClick={() => checked()}>
                                                <HistoryOutlined style={{fontSize: "60px", color: timestamp?.time_in == '-:-' ? '#FF6E35' : '#fff'}}/>
                                            </div>
                                            <div className="shadow-circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div style={{width: "100%"}}>
                                <div>{}</div>
                                <div className="panel-blur" style={{marginBottom: "10px"}}>
                                    <div className="txt-header-body" style={{marginBottom: "10px"}}>{t("Yo sub!")}</div>
                                    <div style={{color: "#fff", opacity: "0.7", marginBottom: "15px"}}>{dayjs(nowdate).format("DD/MM/YYYY")}</div>
                                    <div className="curren-time">{crtime}</div>
                                </div>

                                <div className="panel-blur" style={{marginBottom: "10px"}}>
                                    <div style={{display: "grid", gridTemplateColumns: "50% 50%", gap: "15px"}}>
                                        <div style={{borderRight: "1px solid #fff"}}>
                                            <div>
                                                <div className="txt-header-body" style={{marginBottom: "20px"}}>{t("Daily Work!")}</div>
                                                <div style={{color: "#fff", fontSize: "10px", opacity: "0.5"}}>{t("เวลาเข้างาน")}</div>
                                                <div className="font-res" style={{color: "#f07d51", fontWeight: "bold"}}>{timestamp?.time_in !== '-:-' ? timestamp?.time_in : "-"}</div>
                                                <div style={{color: "#fff", fontSize: "10px", opacity: "0.5"}}>{t("เวลาออกงาน")}</div>
                                                <div className="font-res" style={{color: "#f07d51", fontWeight: "bold"}}>{timestamp?.time_out !== '-:-' ? timestamp?.time_out : "-"}</div>
                                                <div style={{color: "#fff", fontSize: "10px", opacity: "0.5"}}>{t("เวลาทำงาน")}</div>
                                                <div className="font-res" style={{color: "#f07d51", fontWeight: "bold"}}>{timestamp?.time_summary !== '-:-' ? timestamp?.time_summary : "-"}</div>
                                            </div>
                                        </div>
                                        <div className="containers-circle-check">
                                            <div className="circle-check-in-last">
                                                <div className="circle-check-in-middle">
                                                    <div className={`${timestamp?.time_in == '-:-' ? 'circle-check-in-first' : 'circle-check-out-first' }`} style={{cursor: "pointer"}} onClick={() => checked()}>
                                                        <HistoryOutlined style={{fontSize: "60px", color: timestamp?.time_in == '-:-' ? '#FF6E35' : '#fff'}}/>
                                                    </div>
                                                    <div className="shadow-circle"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div style={{marginTop: "50px", color: "#fff", textAlign: "center", opacity: "0.5"}}>made by @devjuuxo x @kompkw version 0.05</div> */}
                                </div>

                                <div style={{marginBottom: "10px"}}>     
                                    <Badge.Ribbon text={<div><NotificationOutlined style={{marginRight: "5px"}}/>{"Announce"}</div>} placement="start" className="ribbon-color-custom" style={{marginBottom: "10px"}}>
                                        <div className="panel-blur" style={{color: "#fff", paddingTop: "50px"}}>
                                            <div id="scroll-container">
                                                <div id="scroll-text">
                                                    {"ขอให้วันนี้เป็นวันที่ดีของคุณ!!"}
                                                </div>
                                            </div>
                                        </div>
                                    </Badge.Ribbon>
                                </div>
                            </div>
                        </div>
                        
                        <div className="panel-menu"><MenuCustom/></div>
                        {/* <div id="fx-panel"></div> */}

                    </div>
                </div>
            </Spin>
        </ConfigProvider>
    )
}