import { useNavigation, useTranslate } from "@refinedev/core";
import { Button, Checkbox, Form, Input, Layout, Modal, Spin, Tag, message, notification } from "antd";
import "./styles.css";
import { API_URL, DOMAIN_URL } from "../../../constants";
import { axiosInstance } from "@refinedev/strapi-v4";
import { useState } from "react";
import { CheckCircleFilled, FileOutlined} from '@ant-design/icons';

export interface IRegisForm {
    username: string;
    password: string;
    remember: boolean;
}

export const Register: React.FC = () => {
    const t = useTranslate();
    const [form] = Form.useForm<IRegisForm>();
    const { push } = useNavigation();

    localStorage.clear();

    const [modal, contextHolder] = Modal.useModal();
    const [messageApi, MsgHolder] = message.useMessage();
    const [isLoading, setLoading] = useState<boolean>(false);

    let page = window?.location?.href.split("/");
    let pageSPLIT: any = page[3].split("?");
    let pageTOKEN: any = pageSPLIT[1];

    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description: t("สมัครสมาชิกเสร็จสิ้น ระบบกำลังพาท่านกลับไปหน้า Login"),
            duration: 3,
            onClose() {
                push("/")
            },
        });
    };

    return(
        <div id="container-page-bg">
            {contextHolder}
            {MsgHolder}
            <div className="panel-form">
                <div className="inline-panel-form">
                    <img className="logo_pdi" src={`${DOMAIN_URL}/images/svg/logo.svg`} alt="icn"/>
                    <div className="txt-header mt-30px">{t("Register Now")}</div>
                    <div className="sub-txt-header mt-20px">{t("Fill out information for registration.")}</div>
                    <div className="mt-30px">
                        <Spin spinning={isLoading}>
                            <Form<IRegisForm>
                                layout="vertical"
                                form={form}
                                onFinish={async (values: any) => {
                                    setLoading(true);
                                    setTimeout(() => {
                                        let body: any = {
                                            email: values.email,
                                            username: values.email,
                                            first_name: values.first_name,
                                            last_name: values.last_name,
                                            password: values.password1,
                                            tel: ""
                                        }
            
                                        axiosInstance.post(`${API_URL}/api/v1/user/create`,
                                            body,
                                            { headers: {} }
                                            )
                                        .then((response) => {
                                            setTimeout(() => {
                                                successNoti();
                                            },100)
                                        })
                                        .catch(error => {
                                            console.error('error ',error);
                                        })
                                    },500);
                                }}
                                requiredMark={false}
                                initialValues={{
                                    remember: false,
                                }}
                            >
                                <Form.Item
                                    name="email"
                                    className="mb-30px"
                                    label={<span style={{color: "#49494A", fontWeight: 600, fontSize: "16px"}}>{"Email"}</span>}
                                    rules={[{ required: true, message: "Please Enter Email" }]}
                                >
                                    <Input
                                        className="font-size16 input-h45"
                                        size="large"
                                        type="email"
                                        placeholder="Email"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="first_name"
                                    className="mb-30px"
                                    label={<span style={{color: "#49494A", fontWeight: 600, fontSize: "16px"}}>{"First Name"}</span>}
                                    rules={[{ required: true, message: "Please Enter First name" }]}
                                >
                                    <Input
                                        className="font-size16 input-h45"
                                        size="large" 
                                        placeholder="First Name"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="last_name"
                                    className="mb-30px"
                                    label={<span style={{color: "#49494A", fontWeight: 600, fontSize: "16px"}}>{"Last Name"}</span>}
                                    rules={[{ required: true, message: "Please Enter Last name" }]}

                                >
                                    <Input
                                        className="font-size16 input-h45"
                                        size="large" 
                                        placeholder="Last Name"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="password1"
                                    className="mb-30px"
                                    label={<span style={{color: "#49494A", fontWeight: 600, fontSize: "16px"}}>{"Password"}</span>}
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (value.length >= 8 && value.length <= 16) {
                                                    return Promise.resolve();
                                                }

                                                if (value.length <= 8 && value.length > 0  || value.length >= 16) {
                                                    return Promise.reject(new Error('Password must be 8-16 characters only '));
                                                }

                                                if (value.length == 0) {
                                                    return Promise.reject(new Error('please input your password'));
                                                }

                                                if (value == "") {
                                                    return Promise.reject(new Error('please input your password'));
                                                }

                                            },
                                        }),
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password
                                        className="font-size16 input-h45"
                                        type="password"
                                        placeholder="Password"
                                        size="large"
                                    />
                                </Form.Item>

                                <div style={{marginTop: "3rem"}}>
                                    <Button
                                        className="btn-sign-in"
                                        type="primary"
                                        htmlType="submit"
                                        block
                                        style={{boxShadow: "unset"}}
                                    >
                                        {t("Sign up")}
                                    </Button>
                                </div>

                                <div className="font-size18 mt-30px" style={{textAlign: "center", fontWeight: "600"}}>
                                    <span style={{marginRight: "0.3rem", color: "#7E8B9E"}}>Already have an account? </span>
                                    <span
                                        className="txt-hover"
                                        style={{color: "#E14D56"}}
                                        onClick={() => push("/")}    
                                    >
                                        {t("Sign in")}
                                    </span>
                                </div>
                            </Form>
                        </Spin>
                    </div>
                </div>
            </div>
        </div>
    )
}