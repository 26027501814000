import { IResourceComponentsProps, useCustom, useLogout, useTranslate } from "@refinedev/core"
import { Spin, ConfigProvider, Table, Tag, DatePicker, Row, Col, Select, Form } from "antd";
import { API_URL, DOMAIN_URL } from "../../constants";
import { useState } from "react";
import { axiosInstance } from "@refinedev/strapi-v4";
import dayjs from "dayjs";
import { LoadingOutlined } from '@ant-design/icons';
import { MenuCustom } from "components/menu_footer";

let startWork: any = 2023;
let nowYear: any = dayjs(new Date)?.format("YYYY");
let resultWork: any = Number(nowYear) - Number(startWork);

export const HistoryPage: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const [formSearch] = Form.useForm<any>();
    const TOKEN: any = localStorage.getItem("AUTH");
    const [dataTB, setdataTB] = useState<any>([]);
    const [dataTBL, setdataTBL] = useState<any>([]);
    const [tk, settk] = useState<boolean>(true);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [isLoadingTB, setisLoadingTB] = useState<boolean>(false);
    const { mutate: mutateLogout } = useLogout();

    const [pageSize, setPageSize] = useState<any>(7);
    const [optionMonth, setoptionMonth] = useState<any>([]);
    const [optionYear, setoptionYear] = useState<any>([]);

    const { data: dataUser, refetch: refetchUser} = useCustom<any>({
        url: `${API_URL}/api/user-profile/find_by_user`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
        },
        queryOptions:{
            async onSuccess(data) {
                let dataLoad: any = data?.data;

                settk(!tk);
                await getTheme(dataLoad?.user_profile?.current_theme || "styles-main");
                await getFilter();
                setTimeout(() => {
                    getHistory();
                }, 300);

            },
            onError(err: any){
                localStorage?.clear();
                mutateLogout();
            }
        },
    });

    function getHistory(){
        axiosInstance.get(`${API_URL}/api/attendances/user_history`, { headers: { Authorization: `Bearer ${TOKEN}` } })
        .then((res) => {
            let respon: any = res?.data;
            let filterdt: any = [];

            for (let index = 0; index <= resultWork; index++) {
                let fiteminYear: any = respon?.filter((item: any) => dayjs(item?.createdAt).format("YYYY") == startWork + index);
                filterdt.push(...fiteminYear.reverse());
            }

            let reversedt: any = filterdt?.reverse();
            setdataTB((pre: any) => reversedt);
            setdataTBL((pre: any) => reversedt);
            settk(!tk);
            setTimeout(() => {
                setLoading(false);
            }, 300);
        })
        .catch(error => {
            console.error('error ',error);
        });
    }

    async function getFilter(){
        axiosInstance.get(`${API_URL}/api/clock/getMonthYear`,
            { headers: { Authorization: `Bearer ${TOKEN}` } }
        )
        .then((res) => {
            let response: any = res?.data;
            let renderoptionYear: any = [];
            let renderoptionMonth: any = response[nowYear]?.map((item: any) => {return {value: item?.number, label: item?.name}});

            for (let index = 0; index <= resultWork; index++) {
                renderoptionYear.push({
                    value: Number(startWork) + (index),
                    label: Number(startWork) + (index)
                })
            }

            setoptionYear((pre: any) => renderoptionYear);
            setoptionMonth((pre: any) => renderoptionMonth);
            settk(!tk);
        })
        .catch(error => {

        });
    }

    async function getTheme(value: any){
        let theme: any = value;
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `${DOMAIN_URL}/theme/${theme}.css`;
        document.head.appendChild(link);
    }

    const columns: any = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 100,
            sorter: (a:any, b:any) => (a?.time_date ? a?.time_date: "").localeCompare(b?.time_date ? b?.time_date: ""),
            render: (value:any, record:any) => {
                return (<span>{dayjs(record?.createdAt)?.format("DD/MM/YYYY")}</span>)
            },
        },
        {
            title: <div style={{marginRight: "30px"}}>{'Clock In'}</div>,
            dataIndex: 'time_in',
            key: 'time_in',
            width: "auto",
            sorter: (a:any, b:any) => (a?.time_in ? a?.time_in: "").localeCompare(b?.time_in ? b?.time_in: ""),
            render: (value:any, record:any) => {
                return (<span>{record?.time_in ? record?.time_in : "-"}</span>)
            },
        },
        {
            title: <div style={{marginRight: "30px"}}>{'Clock Out'}</div>,
            dataIndex: 'time_out',
            key: 'time_out',
            width: "auto",
            sorter: (a:any, b:any) => (a?.time_out ? a?.time_out: "").localeCompare(b?.time_out ? b?.time_out: ""),
            render: (value:any, record:any) => {
                return (<span>{record?.time_out ? record?.time_out : "-"}</span>)
            },
        },
    ];

    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    function onSearch(){
        setisLoadingTB(true);
        let dataLoad:any = dataTBL;
        let values: any = formSearch?.getFieldsValue();
        let month: any = values?.selected_month && values?.selected_month !== "" ? values?.selected_month : undefined;
        let year: any = values?.selected_year && values?.selected_year !== "" ? values?.selected_year : undefined;

        let newDT: any = [];
        newDT = dataLoad?.filter((e:any) => 
            (month ? dayjs(e.createdAt)?.format('MM') == month : e?.createdAt !== undefined) &&
            (year ? dayjs(e.createdAt)?.format('YYYY') == year : e?.createdAt !== undefined)
        );

        setdataTB((pre: any) => newDT);
        settk(!tk);
        setTimeout(() => {
            setisLoadingTB(false);
        }, 300);
    }
    
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgMask: "rgba(27, 26, 24, 1)",
                    colorPrimary: "#f07d51",
                },
            }}
        >
            <Spin 
                spinning={isLoading} 
                // indicator={
                //     <div className="custom-spin-dot">
                //         <LoadingOutlined style={{marginRight: "15px"}}/>
                //         <span>{t("โหลดข้อมูล")}</span>
                //     </div>
                // }
                indicator={<></>}
                fullscreen={isLoading}
            >
                <div className="layout-check-in">
                    <div className="body-container-check-in">
                        <div className="panel-header">
                            <div className="font-header">
                                <span className="main">{t("i24")}</span>
                                <span className="sub-main">{t("HOURS")}</span>
                            </div>
                        </div>
                        <div className="panel-body panel-full-h">
                            <div>
                                <div className="panel-txt-header">
                                    <span><Tag color="#ff0000">{t("NEWS")}</Tag>{t("Your Attendances")}</span>
                                </div>
                                <div style={{marginBottom: "10px"}}>
                                    <Form
                                        layout="vertical"
                                        form={formSearch}
                                    >
                                        <Row gutter={12}>
                                            <Col span={14} className="gutter-row">
                                                <Form.Item 
                                                    label=" "
                                                    name="selected_month"
                                                    style={{marginBottom: 0, width: "100%"}}
                                                    className="form-non-txt"
                                                >
                                                    <Select 
                                                        allowClear 
                                                        size="large" 
                                                        style={{width: "100%"}} 
                                                        placeholder="Month"
                                                        options={optionMonth}
                                                        onChange={onSearch}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={10} className="gutter-row">

                                                <Form.Item 
                                                    label=" "
                                                    name="selected_year"
                                                    style={{marginBottom: 0, width: "100%"}}
                                                    className="form-non-txt"
                                                >
                                                    <Select 
                                                        allowClear 
                                                        size="large" 
                                                        style={{width: "100%"}} 
                                                        placeholder="Year"
                                                        options={optionYear}
                                                        onChange={onSearch}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                                <div style={{maxWidth: "430px", backgroundColor: "#fff", borderRadius: "15px"}}>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                            Table: {
                                                headerColor: "#272625",
                                                footerColor: "#005dff"
                                            },
                                            },
                                        }}
                                    >
                                        <Table 
                                            dataSource={dataTB ? dataTB : []} 
                                            columns={columns}
                                            loading={isLoadingTB}
                                            // summary={5}
                                            pagination={{
                                                size: "small",
                                                pageSize: pageSize,
                                                onChange: handlePageSizeChange,
                                                showSizeChanger: false, 
                                            }}
                                        />
                                    </ConfigProvider>
                                </div>
                            </div>
                        </div>
                        <div className="panel-menu"><MenuCustom/></div>
                    </div>
                </div>
            </Spin>
        </ConfigProvider>
    )
}