import { Avatar, Badge, Button, Card, Checkbox, Form, Input, Layout, Modal, Segmented, Spin, Tag, notification } from "antd";
import "./styles.css";
import { API_URL, DOMAIN_URL, TOKEN_KEY } from "../../../constants";
import { useNavigation, useTranslate } from "@refinedev/core";
import { axiosInstance } from "@refinedev/strapi-v4";
import { useState } from "react";
import { SafetyOutlined, ArrowRightOutlined, RedoOutlined, CheckCircleFilled } from '@ant-design/icons';

export const ForgotpwdPage: React.FC = () => {
    const t = useTranslate();
    const [form] = Form.useForm<any>();
    const { push } = useNavigation();
    
    const [Step, setStep] = useState<any>(0);
    const [mdopen, setmdopen] = useState(false);
    const [isLoading, setLoading] = useState<boolean>(false);

    let page = window?.location?.href.split("/");
    let pageSPLIT: any = page[3].split("?");
    let pageTOKEN: any = pageSPLIT[1];

    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description: t("เปลี่ยนรหัสผ่านสำเร็จ! อีก 3วินาทีจะไปยังหน้า /login"),
            duration: 3,
            onClose() {
                if(pageTOKEN == undefined){
                    onclickSN("un-token");
                }else if(pageTOKEN !== undefined){
                    onclickSN("token");
                }
            },
        });
    };

    const warningNoti = () => {
        notification['warning']({
            message: "Warning",
            description: t("ส่งข้อมูลยืนยันไปยัง E-mail สำเร็จแล้ว กรุณานำรหัสมายืนยัน"),
            duration: 3
        });
    };

    const failNoti = (type: any) => {
        notification['error']({
            message: "Faild",
            description: type == "TM" ? t("รูปแบบของ E-mail ผิดพลาดกรุณากรอกข้อมูลใหม่")
                :type == "IP" ? t("กรุณาใส่ E-mail ที่ถูกต้องสำหรับการตั้งรหัสผ่าน")
                :type == "WM" ? t("ไม่พบ E-mail นี้ในระบบ กรุณาตรวจสอบใหม่อีกครั้ง")
                :type == "WC" && t("Verify Code ไม่ถูกต้องกรุณาตรวจสอบใหม่อีกครั้ง"),
            duration: 3,
        });
    };

    function onclickSN(mode: any){
        if(mode == "un-token"){
            push(`/login`);
        }else if(mode == "token"){
            push(`/login?${pageTOKEN}`)
        }
    }

    function sendMAIL(){
        let email: any = form?.getFieldValue(["email"]);
        let checkMAIL: any = email?.search("@")
        if((email?.length > 0 || email !== undefined) && checkMAIL !== -1){
            axiosInstance.post(`${API_URL}/api/v1/user_profile/forgetPass`,
                {
                    identifier: String(email)
                })
            .then((response) => {
                let res = response?.data;
                if(res?.result == "not found"){
                    failNoti("WM");
                }else if(res?.result == "success"){
                    warningNoti();
                    setStep(1);
                }
            })
            .catch(error => {

            });
        }else{
            if(email?.length > 0 && checkMAIL == -1){
                failNoti("TM");
            }
            
            if(email?.length == 0 || email == undefined){
                failNoti("IP");
            }
        }
    }

    return(
        <div>
            <div id="container-page-bg">
                <div className="panel-form">
                    <div className="inline-panel-form">
                        <img className="logo_pdi" src={`${DOMAIN_URL}/images/svg/logo.svg`} alt="icn"/>
                        <div className="txt-header mt-30px">{t("Forgot Password!")}</div>
                        <div className="sub-txt-header mt-5rem">
                            <span>{t("Enter your email address to send the")}</span><br />
                            <span style={{color: "#E53D2A"}}>{t("verification code ")}</span><span>{t("that will be used to ")}</span><br />
                            <span>{t("reset your password")}</span>
                        </div>
                        <div className="mt-5rem">
                            <Spin spinning={isLoading}>
                                <Form
                                    layout="vertical"
                                    form={form}
                                    onFinish={async (values: any) => {
                                        let email: any = form?.getFieldValue(["email"]);
                                        axiosInstance.post(`${API_URL}/api/v1/user_profile/forgetPassConfirm`,
                                            {
                                                identifier: String(email),
                                                code: values?.verify_code,
                                                password: values?.password2
                                            })
                                        .then((response) => {
                                            let res = response?.data;
                                            if(res?.status == 200){
                                                setLoading(true);
                                                setTimeout(() => {
                                                    successNoti();
                                                },300)
                                            }else if(res?.status == 404){
                                                failNoti("WC");
                                            }
                                        })
                                        .catch(error => {
                            
                                        });
                                    }}
                                    requiredMark={false}
                                    initialValues={{
                                        remember: false,
                                    }}
                                >
                                    {Step == 0 &&
                                        <Form.Item
                                            name="email"
                                            className="mb-10px"
                                            label={<span style={{color: "#49494A", fontWeight: 600, fontSize: "16px"}}>{"Email"}</span>}
                                        >
                                            <Input
                                                className="font-size16 input-h45"
                                                size="large"
                                                type="email"
                                                placeholder="Email"
                                            />
                                        </Form.Item>
                                    }

                                    {Step == 1 &&
                                        <div className="visible_for_step">
                                            <Form.Item
                                                name="verify_code"
                                                label={<span style={{color: "#49494A", fontWeight: 600, fontSize: "16px"}}>{"Verify Code"}</span>}
                                                className="mb-30px"
                                                rules={[{ required: true, message: 'Please input your verify code' }]}
                                            >
                                                <Input
                                                    className="font-size16 input-h45"
                                                    size="large"
                                                    placeholder="Verify code"
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="password1"
                                                className="mb-30px"
                                                label={<span style={{color: "#49494A", fontWeight: 600, fontSize: "16px"}}>{"New Password"}</span>}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'please input your password',
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        async validator(_, value) {
                                                            if((value?.length <= 8 && value?.length >= 1) || value?.length >= 16){
                                                                return Promise.reject(new Error('Password must be 8-16 characters only '));
                                                            }else if(value?.length >= 8 || value?.length <= 16){
                                                                return Promise.resolve();
                                                            }
                                                        },
                                                    }),
                                                ]}
                                                hasFeedback
                                            >
                                                <Input.Password
                                                    className="font-size16 input-h45"
                                                    type="password"
                                                    placeholder="New Password"
                                                    size="large"
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="password2"
                                                className="mb-10px"
                                                label={<span style={{color: "#49494A", fontWeight: 600, fontSize: "16px"}}>{"Confirm Password"}</span>}
                                                dependencies={['password1']}
                                                hasFeedback
                                                rules={[{ required: true, message: 'please input your password' },
                                                ({ getFieldValue }) => ({
                                                    async validator(_, value) {
                                                        if((value?.length <= 8 && value?.length >= 1) || value?.length >= 16){
                                                            return Promise.reject(new Error('Password must be 8-16 characters only '));
                                                        }else if(value?.length >= 8 || value?.length <= 16){
                                                            if (!value || getFieldValue('password1') === value) {
                                                                return Promise.resolve();
                                                            }else{
                                                                return Promise.reject(new Error('the two passwords that you entered do not match!'));
                                                            }
                                                        }
                                                    },
                                                }),
                                                ]}
                                            >
                                                <Input.Password
                                                    className="font-size16 input-h45"
                                                    type="password"
                                                    placeholder="Confirm Password"
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </div>
                                    }

                                    <div style={{marginTop: "1.5rem"}}>
                                        <Button
                                            className="btn-reset-pwd"
                                            type="primary"
                                            htmlType="submit"
                                            block
                                            style={{boxShadow: "unset", textTransform: "unset"}}
                                            onClick={() => {Step == 0 ? sendMAIL(): Step == 1 && form?.submit()}}
                                        >
                                            {Step == 0 ? t("Continue") : Step == 1 && t("Reset Password")}
                                        </Button>
                                    </div>

                                    <div className="font-size18 mt-30px" style={{textAlign: "center", fontWeight: "600"}}>
                                        <span style={{marginRight: "0.3rem", color: "#7E8B9E"}}>Already have an account? </span>
                                        <span
                                            className="txt-hover"
                                            style={{color: "#E14D56"}}
                                            onClick={() => push("/")}    
                                        >
                                            {t("Sign in")}
                                        </span>
                                    </div>
                                </Form>
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}