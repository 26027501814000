// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body-container-site {
    background-image: url("https://i.pinimg.com/564x/c4/33/36/c43336eb7f0805d52bf89ff40e752f13.jpg");
}

:where(.body-container-site) .panel-header {
    background-color: #fff;
}

:where(.body-container-site) .panel-header .font-header {
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    color: #000;
    letter-spacing: 2px;
}

.leave-menu {
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 15px 25px;
    transition: 0.3s;
}

.leave-menu:hover {
    background-color: rgb(174, 174, 174);
    transition: 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/pages/leave-work/styles.css"],"names":[],"mappings":"AAAA;IACI,gGAAgG;AACpG;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;IACpC,gBAAgB;AACpB","sourcesContent":[".body-container-site {\n    background-image: url(\"https://i.pinimg.com/564x/c4/33/36/c43336eb7f0805d52bf89ff40e752f13.jpg\");\n}\n\n:where(.body-container-site) .panel-header {\n    background-color: #fff;\n}\n\n:where(.body-container-site) .panel-header .font-header {\n    text-align: center;\n    font-size: 26px;\n    font-weight: bold;\n    color: #000;\n    letter-spacing: 2px;\n}\n\n.leave-menu {\n    background-color: #fff;\n    margin-bottom: 10px;\n    border-radius: 10px;\n    padding: 15px 25px;\n    transition: 0.3s;\n}\n\n.leave-menu:hover {\n    background-color: rgb(174, 174, 174);\n    transition: 0.3s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
