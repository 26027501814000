import { IResourceComponentsProps, useCustom, useGetIdentity, useLogout, useNavigation, useTranslate } from "@refinedev/core"
import "./styles.css";
import { UploadProps } from "antd/es/upload";
import { Avatar, Badge, Button, Col, ConfigProvider, Divider, Form, QRCode, Row, Spin, Upload, message } from "antd";
import { useRef, useState } from "react";
import { CameraOutlined, LoadingOutlined, UserOutlined, ApartmentOutlined, DeleteOutlined, DollarOutlined, ShopOutlined, LogoutOutlined } from '@ant-design/icons';
import Dragger from "antd/es/upload/Dragger";
import { API_URL, DOMAIN_URL } from "../../constants";
import { axiosInstance } from "@refinedev/strapi-v4";
import { MenuCustom } from "components/menu_footer";

export const DetailByID: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [userProfile, setuserProfile] = useState<any>();
    const [pixURL, setpixURL] = useState<any>();

    const { data: dataUser, refetch: refetchUser} = useCustom<any>({
        url: `${API_URL}/api/user-profile/find_by_user`,
        method: "get",
        queryOptions:{
            async onSuccess(data) {
                let dataLoad: any = data?.data;

                console.log(">>> dataLoad", dataLoad)
            },
            onError(err: any){
                localStorage?.clear();
            }
        },
    });

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgMask: "rgba(27, 26, 24, 1)",
                    colorPrimary: "#f07d51",
                },
            }}
        >
            <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: "60px" }} />} fullscreen={isLoading}>
                <div className="layout-profile-unauth">
                    <div className="panel-container-license">
                    <div className="panel-header">
                        <div className="font-header">
                            <span className="main">{t("MY")}</span>
                            <span className="sub-main">{t("LICENSE")}</span>
                        </div>
                    </div>
                    <div className="panel-body panel-full-h-100" style={{padding: "0px 20px"}}>
                        
                    </div>
                    </div>
                </div>
            </Spin>
        </ConfigProvider>
    )
}