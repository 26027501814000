export const SOURCE_URL = "";
export const TOKEN_KEY = "";
export const GOOGLEID  = "";
// export const API_URL  = "https://dev-api.pdi.one"; //BACKEND SIT/UAT
// export const API_URL  = "http://192.168.100.178:10091"; //BACKEND LOCAL
export const DOMAIN_URL  = "https://24h.nueamek.com"; //TEST HTTPS LOCAL
// export const    = "http://192.168.100.131"; //URL LOCAL
// export const DOMAIN_URL  = "http://192.168.100.131:4000"; //URL LOCAL
// export const DOMAIN_URL  = "https://dev-portal.pdi.one"; //URL SIT/UAT 
// export const API_URL  = "http://10.100.100.60:1337"; //BACKEND LOCAL
// export const API_URL  = "http://192.168.100.133:1337"; //BACKEND LOCAL
export const API_URL  = "https://24h-api.nueamek.com"; //BACKEND LOCAL
// export const API_URL  = "https://dev-api.pdi.one"; //BACKEND LOCAL